import { FormattedHTMLMessage, FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import StringField from './form/StringField';

import Button from './common/Button';

const messages = defineMessages({
  QUESTION: {
    id: 'DeleteForm.QUESTION',
    defaultMessage:
      'Do you really want to delete „<strong>{name}</strong>“? This operation cannot be undone.',
  },
  DELETING: {
    id: 'DeleteForm.DELETING',
    defaultMessage: 'Deleting...',
  },
  CANCEL: {
    id: 'DeleteForm.CANCEL',
    defaultMessage: '✖ Cancel',
  },
  DELETE: {
    id: 'DeleteForm.DELETE',
    defaultMessage: 'Delete',
  },
  LABEL_CURRENT_PASSWORD: {
    id: 'ProfileFormComponent.LABEL_CURRENT_PASSWORD',
    defaultMessage: 'Current password',
  },
  USER_DELETE_DESCRIPTION: {
    id: 'ProfileFormComponent.USER_DELETE_DESCRIPTION',
    defaultMessage:
      'All your dashboards and widgets will be permanently deleted. You will need to enter your current password to confirm.',
  },
});

let globalformatMessage;
const required = (value) =>
  value
    ? undefined
    : globalformatMessage &&
      globalformatMessage({ id: 'Validator.MANDATORY_FIELD', defaultMessage: 'Mandatory Field' });

export function DeleteFormComponent({
  handleSubmit,
  error,
  submitting,
  name,
  onCancel,
  intl: { formatMessage },
  showPasswordField = false,
  subMessage = null,
}) {
  if (formatMessage) {
    globalformatMessage = formatMessage;
  }

  return (
    <form onSubmit={handleSubmit}>
      <p>
        <FormattedHTMLMessage {...messages.QUESTION} values={{ name }} />
      </p>
      {subMessage && (
        <p className="pb2">
          <FormattedMessage {...subMessage} />
        </p>
      )}
      {showPasswordField && (
        <>
          <p>
            <FormattedMessage {...messages.USER_DELETE_DESCRIPTION} />
          </p>
          <Field
            name="password"
            component={StringField}
            validate={required}
            config={{
              inputType: 'password',
              label: formatMessage(messages.LABEL_CURRENT_PASSWORD),
              autoComplete: 'new-password',
            }}
          />
        </>
      )}
      {error ? <p className="ssf-red">{error}</p> : null}
      {submitting ? <p className="info tc">{formatMessage(messages.DELETING)}</p> : null}
      <div className="tr">
        <Button
          type="button"
          disabled={submitting}
          onClick={onCancel}
          label={formatMessage(messages.CANCEL)}
          marginRight
        />
        <Button
          activestyle="danger"
          type="submit"
          disabled={submitting}
          label={formatMessage(messages.DELETE)}
          icon="fa fa-trash-alt"
        />
      </div>
    </form>
  );
}

export default reduxForm({ form: 'delete' })(injectIntl(DeleteFormComponent));
