export const CONFIG_SET = 'CONFIG_SET';
export const CONFIG_ERROR = 'CONFIG_ERROR';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_MFA = 'AUTH_MFA';
export const SHOW_MFA = 'SHOW_MFA';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';

export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';
export const TIMEZONE_CHANGED = 'TIMEZONE_CHANGED';

export const WEBSOCKET_ONLINE = 'WEBSOCKET_ONLINE';
export const WEBSOCKET_OFFLINE = 'WEBSOCKET_OFFLINE';
export const WEBSOCKET_MESSAGE = 'WEBSOCKET_MESSAGE';

export const INITIAL_DATA_CLEAR = 'INITIAL_DATA_CLEAR';
export const INITIAL_DATA_SET = 'INITIAL_DATA_SET';
export const INITIAL_DATA_ERROR = 'INITIAL_DATA_ERROR';
export const CREATE_MACHINE = 'CREATE_MACHINE';
export const UPDATE_MACHINE = 'UPDATE_MACHINE';
export const UPDATE_MACHINE_LIST = 'UPDATE_MACHINE_LIST';
export const SCHEMA_UPDATED = 'SCHEMA_UPDATED';
export const UPDATE_DEVICE_CONFIG = 'UPDATE_DEVICE_CONFIG';
export const DELETE_MACHINE = 'DELETE_MACHINE';
export const CREATE_DATASOURCE = 'CREATE_DATASOURCE';
export const UPDATE_DATASOURCE = 'UPDATE_DATASOURCE';
export const DELETE_DATASOURCE = 'DELETE_DATASOURCE';

export const CREATE_ENTITY = 'CREATE_ENTITY';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const DELETE_ENTITY = 'DELETE_ENTITY';

export const FULLSCREEN_ENTER = 'FULLSCREEN_ENTER';
export const FULLSCREEN_EXIT = 'FULLSCREEN_EXIT';

export const WIDGET_CREATED = 'WIDGET_CREATED';
export const WIDGET_UPDATED = 'WIDGET_UPDATED';
export const WIDGET_DELETED = 'WIDGET_DELETED';
export const WIDGETS_UPDATED = 'WIDGETS_UPDATED';

export const DASHBOARD_CREATED = 'DASHBOARD_CREATED';
export const DASHBOARD_UPDATED = 'DASHBOARD_UPDATED';
export const DASHBOARD_DELETED = 'DASHBOARD_DELETED';
export const DASHBOARD_ORDER = 'DASHBOARD_ORDER';
export const DASHBOARD_SHARING_UPDATED = 'DASHBOARD_SHARING_UPDATED';

export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';

export const LIST_SET = 'LIST_SET';
export const LIST_ERROR = 'LIST_ERROR';
export const LIST_PENDING = 'LIST_PENDING';
export const SINGLE_SET = 'SINGLE_SET';
export const SINGLE_ERROR = 'SINGLE_ERROR';

export const RESOURCE_CREATED = 'RESOURCE_CREATED';
export const RESOURCE_UPDATED = 'RESOURCE_UPDATED';
export const RESOURCE_SCHEMA_UPDATED = 'RESOURCE_SCHEMA_UPDATED';
export const RESOURCE_DELETED = 'RESOURCE_DELETED';

// Events
export const LAST_SEEN_RECEIVED = 'LAST_SEEN_RECEIVED';
export const DOCUMENT_CREATED = 'DOCUMENT_CREATED';
export const DOCUMENT_DELETED = 'DOCUMENT_DELETED';
export const STATE_UPDATED = 'STATE_UPDATED';
export const UPDATE_DEVICE_STATE = 'UPDATE_DEVICE_STATE';
export const UPDATE_CURRENT_EVENT = 'UPDATE_CURRENT_EVENT';

export const ACTION_IGNORED = 'ACTION_IGNORED';
