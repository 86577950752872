import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from '../../../components/common/Button';
import TimeRangeSelector from './TimeRangeSelector';
import TimeRangeResetBtn from './TimeRangeResetBtn';
import TimeRangeCurrentButton from './TimeRangeCurrentButton';
import TimeRangeShiftLeft from './TimeRangeShiftLeft';
import TimeRangeShiftRight from './TimeRangeShiftRight';

const messages = defineMessages({
  LABEL_ADD_WIDGET: {
    id: 'DashboardSinglePage.LABEL_ADD_WIDGET',
    defaultMessage: 'Add widget',
  },
  LABEL_EDIT: {
    id: 'DashboardSinglePage.LABEL_EDIT',
    defaultMessage: 'Edit',
  },
  LABEL_REALTIME: {
    id: 'DashboardSinglePage.Realtime',
    defaultMessage: 'Realtime',
  },
  COPY_DASHBOARD: {
    id: 'SelectUserDashboardForm.COPY_DASHBOARD',
    defaultMessage: 'Copy dashboard',
  },
  CANCEL: {
    id: 'DeleteForm.CANCEL',
    defaultMessage: 'Cancel',
  },
  CONFIRM: {
    id: 'DashboardContainerPage.ADD_USER_TO_ACCOUNT_CONFIRM',
    defaultMessage: 'Confirm',
  },
});

const ButtonEdit = styled(Button)`
  @media (max-width: 1125px) {
    & > i {
      margin: 0;
    }
    & > span {
      display: none;
    }
  }
`;

function DashboardNavigationBar(props) {
  const {
    dashboard,
    isFullscreen,
    from,
    to,
    setUpdateMode,
    intl: { formatMessage },
    show_edit,
    timezone_offset,
    can_edit_dashboard = true,
    can_copy_dashboard = false,
    // windowWidth,
    copyDashboard,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  // let pathname = location.pathname;
  // if (pathname.substr(-1) === '/') {
  //   pathname = pathname.substr(0, pathname.length - 1);
  // }
  // pathname = pathname.split('/').pop();

  // TODO: update state without reloading page

  // const is_widget_edit = pathname === 'update';

  const now_from = (dashboard.config && dashboard.config.now_from) || 'now-30m';
  const now_to = (dashboard.config && dashboard.config.now_to) || 'now+5m';

  return isFullscreen && show_edit ? (
    <div className="tr flex pl2 items-center">
      <TimeRangeShiftLeft
        from={from}
        to={to}
        navigate={navigate}
        location={location}
        timezone_offset={timezone_offset}
        // is_widget_edit={is_widget_edit}
      />
      <TimeRangeSelector dashboard={dashboard} can_edit_dashboard={can_edit_dashboard} />
      <TimeRangeShiftRight
        from={from}
        to={to}
        navigate={navigate}
        location={location}
        timezone_offset={timezone_offset}
        // is_widget_edit={is_widget_edit}
      />
      <TimeRangeResetBtn dashboard={dashboard} />
      <TimeRangeCurrentButton
        now_from={now_from}
        now_to={now_to}
        navigate={navigate}
        location={location}
        // is_widget_edit={is_widget_edit}
      />
    </div>
  ) : (
    <div className="tr flex pl2 items-center">
      {show_edit === false || !can_edit_dashboard ? null : (
        <ButtonEdit
          // label-ns={formatMessage(messages.LABEL_EDIT)}
          title={formatMessage(messages.LABEL_EDIT)}
          icon="fa fa-pencil-alt"
          to={{
            pathname: `/dashboard/${dashboard.id}/updateWidgets/`,
            search: location.search,
            state: { is_edit: true },
          }}
          onClick={() => setUpdateMode(true)}
          marginRight
          activestyle="primary"
        />
      )}

      {show_edit === false ? null : (
        <TimeRangeShiftLeft
          from={from}
          to={to}
          navigate={navigate}
          location={location}
          timezone_offset={timezone_offset}
          // is_widget_edit={is_widget_edit}
        />
      )}
      {show_edit === false ? null : (
        <TimeRangeSelector dashboard={dashboard} can_edit_dashboard={can_edit_dashboard} />
      )}
      {show_edit === false ? null : (
        <TimeRangeShiftRight
          from={from}
          to={to}
          navigate={navigate}
          location={location}
          timezone_offset={timezone_offset}
          // is_widget_edit={is_widget_edit}
        />
      )}
      {show_edit === false ? null : <TimeRangeResetBtn dashboard={dashboard} />}
      {show_edit === false ? null : (
        <TimeRangeCurrentButton
          now_from={now_from}
          now_to={now_to}
          navigate={navigate}
          location={location}
          // is_widget_edit={is_widget_edit}
        />
      )}
      {!can_edit_dashboard && can_copy_dashboard ? (
        <Button
          title={formatMessage(messages.COPY_DASHBOARD)}
          icon="fa fa-clone"
          onClick={() =>
            Swal.fire({
              title: dashboard.name,
              text: formatMessage(messages.COPY_DASHBOARD) + '?',
              showCancelButton: true,
              confirmButtonColor: '#019958',
              confirmButtonText: formatMessage(messages.CONFIRM),
              heightAuto: true,
              allowEscapeKey: true,
              allowEnterKey: false,
              cancelButtonText: formatMessage(messages.CANCEL),
              background: '#f2f3f5',
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                copyDashboard({
                  user_id: dashboard.user_id,
                  copy_dashboard_id: dashboard.id,
                });
              }
            })
          }
          marginLeft
        />
      ) : null}
    </div>
  );
}

export default connect((state) => ({
  isFullscreen: state.fullscreen,
  from: state.search.from,
  to: state.search.to,
  timezone_offset: state.auth.user.timezone_offset,
}))(injectIntl(DashboardNavigationBar));
