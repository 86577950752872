import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getFormValues, Field, reduxForm } from 'redux-form';
import { defineMessages, injectIntl } from 'react-intl';
import dateMath from '../../../utils/dateMath';
import Button from '../../../components/common/Button';
import StringField from '../../../components/form/StringField';
import BooleanField from '../../../components/form/BooleanField';
import DatetimeComponent from '../../../components/form/DatetimeComponent';
import CollapseChildren from '../../../components/common/CollapseChildren';

const messages = defineMessages({
  FROM: {
    id: 'TimeRangeSelectorForm.FROM',
    defaultMessage: 'From',
  },
  TO: {
    id: 'TimeRangeSelectorForm.TO',
    defaultMessage: 'To',
  },
  SAVE: {
    id: 'TimeRangeSelectorForm.SAVE',
    defaultMessage: 'Save',
  },
  CANCEL: {
    id: 'TimeRangeSelectorForm.CANCEL',
    defaultMessage: 'Cancel',
  },
  SYNTAXEXPLANATION: {
    id: 'TimeRangeSelector.SYNTAXEXPLANATION',
    defaultMessage: 'You can use the elasticsearch date math format to enter a date',
  },
  BUTTON_STATIC_MODE: {
    id: 'TimeRangeSelector.BUTTON_STATIC_MODE',
    defaultMessage: 'Manually select from and to Date',
  },
  STATIC_MODE: {
    id: 'TimeRangeSelector.STATIC_MODE',
    defaultMessage: 'Choose static from and to dates',
  },
  EXPERT_MODE: {
    id: 'TimeRangeSelector.EXPERT_MODE',
    defaultMessage: 'Expert mode',
  },
  PERMANENTLY_SAVE: {
    id: 'TimeRangeSelector.PERMANENTLY_SAVE',
    defaultMessage: 'PERMANENTLY_SAVE',
  },
  NOW_BUTTON: {
    id: 'TimeRangeSelector.NOW_BUTTON',
    defaultMessage: 'Change values of the Now button',
  },
  TIME: {
    id: 'TIME',
    defaultMessage: 'Time',
  },
  TODAY: {
    id: 'TODAY',
    defaultMessage: 'Today',
  },
});

class TimeRangeSelectorForm extends PureComponent {
  render() {
    const {
      handleSubmit,
      error,
      submitting,
      onCancel,
      intl: { formatMessage },
      children,
      timezone_offset,
      can_edit_dashboard = true,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        {children}
        <div className="flex mt1">
          <h3>{formatMessage(messages.STATIC_MODE)}</h3>
        </div>
        <div className="flex mt1">
          <Field
            name="from"
            component={DatetimeComponent}
            config={{
              label: formatMessage(messages.FROM),
              label_time: formatMessage(messages.TIME),
              label_today: formatMessage(messages.TODAY),
              valuePreprocess: (val) => val && new Date(dateMath(val, timezone_offset)),
              autoComplete: 'off',
            }}
          />
          <Field
            name="to"
            component={DatetimeComponent}
            config={{
              label: formatMessage(messages.TO),
              label_time: formatMessage(messages.TIME),
              label_today: formatMessage(messages.TODAY),
              valuePreprocess: (val) => val && new Date(dateMath(val, timezone_offset) - 1),
              autoComplete: 'off',
            }}
          />
        </div>
        {can_edit_dashboard && (
          <>
            <CollapseChildren label={formatMessage(messages.NOW_BUTTON)}>
              <div className="flex mt3 flex-column">
                <Field
                  name="now_from"
                  component={StringField}
                  config={{
                    label: formatMessage(messages.FROM),
                    autoComplete: 'off',
                  }}
                />
                <Field
                  name="now_to"
                  component={StringField}
                  config={{
                    label: formatMessage(messages.TO),
                    autoComplete: 'off',
                  }}
                />
              </div>
              <div className="flex mt1">
                <p>
                  {formatMessage(messages.SYNTAXEXPLANATION)} (
                  <a
                    href="https://www.elastic.co/guide/en/elasticsearch/reference/6.8/common-options.html#date-math"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link
                  </a>
                  ).
                </p>
              </div>
            </CollapseChildren>
            <CollapseChildren label={formatMessage(messages.EXPERT_MODE)}>
              <div className="flex mt3 flex-column">
                <Field
                  name="from"
                  component={StringField}
                  config={{
                    label: formatMessage(messages.FROM),
                    autoComplete: 'off',
                  }}
                />
                <Field
                  name="to"
                  component={StringField}
                  config={{
                    label: formatMessage(messages.TO),
                    autoComplete: 'off',
                  }}
                />
              </div>
              <div className="flex mt1">
                <p>
                  {formatMessage(messages.SYNTAXEXPLANATION)} (
                  <a
                    href="https://www.elastic.co/guide/en/elasticsearch/reference/6.8/common-options.html#date-math"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link
                  </a>
                  ).
                </p>
              </div>
            </CollapseChildren>

            <div className="flex mt3">
              <Field
                name="permanently_save"
                component={BooleanField}
                config={{ label: formatMessage(messages.PERMANENTLY_SAVE) }}
              />
            </div>
          </>
        )}

        {error ? <p className="ssf-red">{error}</p> : null}
        {submitting ? <p className="info tc">...</p> : null}

        <div className="flex mt3 mb4 pb3">
          <Button
            type="button"
            onClick={onCancel}
            label={formatMessage(messages.CANCEL)}
            marginRight
            disabled={submitting}
          />
          <Button
            type="submit"
            label={formatMessage(messages.SAVE)}
            activestyle="primary"
            disabled={submitting}
          />
        </div>
      </form>
    );
  }
}

export default connect((state) => ({
  currentFormData: getFormValues('timeRangeSelector')(state) || {},
}))(injectIntl(reduxForm({ form: 'timeRangeSelector' })(injectIntl(TimeRangeSelectorForm))));
