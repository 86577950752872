import { PureComponent } from 'react';
// import { Field } from 'redux-form';
import { connect } from 'react-redux';
// import queryString from 'query-string';
import { injectIntl, defineMessages } from 'react-intl';
import { RemoveCircleOutline, Close } from '@mui/icons-material';
import styled from 'styled-components';
import { SubmissionError } from 'redux-form';
import { components } from 'react-select';
import isEqual from 'lodash/isEqual';
import { memoize } from 'proxy-memoize';
// import Select from 'react-select';
// import queryString from 'query-string';
// import request from '../../../api/request';
import Button from '../../../components/common/Button';
import { StyledSelect } from '../../../controls/StyledSelect';
// import SelectDatasourceField from '../../../components/form/SelectDatasourceField';
// import FieldWrapper from '../../../components/form/FieldWrapper';
import { patchDashboard } from '../../../api/dashboard';
import { dashboardUpdated } from '../../../actions/dashboard';
import { getValue } from '../../../utils/selectinput';
import { getDevicesReduced } from '../../../components/widget/widgets/utils/memoize';
import { findDeviceByDatasource } from '../../../components/widget/widgets/utils/selectors';
import { canEditDashboard } from '../../../utils/dashboard';

const messages = defineMessages({
  FILTER: {
    id: 'DashboardFilterComponent.FILTER',
    defaultMessage: 'Filter',
  },
  TITLE: {
    id: 'DashboardFilterComponent.TITLE',
    defaultMessage: 'Filter settings:',
  },
  ADD_NEW: {
    id: 'DeviceFilterComponent.ADD_NEW',
    defaultMessage: 'Add new',
  },
  DELETE: {
    id: 'DeleteForm.DELETE',
    defaultMessage: 'Delete',
  },
  SAVE: {
    id: 'DashboardFilterComponent.SAVE',
    defaultMessage: 'Save',
  },
  PLACEHOLDER_DATASOURCE: {
    id: 'DashboardFilterComponent.PLACEHOLDER_DATASOURCE',
    defaultMessage: 'Select datasource',
  },
  PLACEHOLDER_PROPERTY: {
    id: 'DashboardFilterComponent.PLACEHOLDER_PROPERTY',
    defaultMessage: 'Select property',
  },
  PLACEHOLDER_VALUE: {
    id: 'DashboardFilterComponent.PLACEHOLDER_VALUE',
    defaultMessage: 'Enter value',
  },
  NO_ITEM: {
    id: 'ResourceListPage.NO_ITEM',
    defaultMessage: 'No entries found',
  },
});

const FilterContainer = styled.div`
  position: relative;
`;
const FilterTitle = styled.h4`
  color: ${({ theme }) => theme.inputField.color};
  margin: 0;
  margin-bottom: 0.3rem;
  text-align: left;
`;
const InputField = styled.input`
  color: ${({ theme }) => theme.color};
  border: none;
  border-radius: 0.2rem;
  padding: 0.2rem;
  background: ${({ theme }) => theme.inputField.background};
`;
const HolderDiv = styled.div`
  width: 14rem;
`;
const FilterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #7f7e80;
  padding: 0.5rem 0;
`;
const FilterMenu = styled.form`
  position: absolute;
  padding: 1rem;
  z-index: 1000;
  top: 2.3rem;
  border-radius: 0.2rem;
  background: ${({ theme }) => theme.div.background};
  color: ${({ theme }) => theme.div.color};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  right: 0;
`;
const ClearButton = styled.button`
  color: grey;
  border: none;
  background: transparent;
  padding: 0;
  vertical-align: middle;
  margin-left: 0.5rem;
  & > svg {
    font-size: 0.9rem;
    vertical-align: middle;
  }
`;

const CloseButton = styled(Close)`
  padding: 0;
  cursor: pointer;
  float: right;
`;

const FilterButtonsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const DeleteFilterButton = styled(RemoveCircleOutline)`
  color: red;
  font-size: 0.6rem;
`;

function getOptionsDatasource(machines) {
  return machines.reduce(
    (a, m) => [
      ...a,
      ...(m.datasources
        ? m.datasources.map((ds) => {
            if (m.datasources.length > 1) {
              return {
                value: ds.datasource_id,
                label: `${m.name} > ${ds.name}`,
                machine_id: m.id,
              };
            }
            return {
              value: ds.datasource_id,
              label: `${m.name}`,
              machine_id: m.id,
            };
          })
        : []),
    ],
    []
  );
}

function getDatasourceKeys({ device_schemas, datasourceId }) {
  const datasource = Object.keys(device_schemas)
    .reduce((arr, machineId) => arr.concat(device_schemas[machineId].datasources || []), [])
    .find((ds) => ds.datasource_id === datasourceId);

  if (!datasource) {
    return { keys: null, schema: null };
  }

  let schema = datasource.document_schema;
  if (datasource.function === 'data') {
    const device_id = findDeviceByDatasource(device_schemas, datasourceId);
    if (device_id && device_schemas[device_id] && device_schemas[device_id].schema) {
      schema = device_schemas[device_id].schema;
    }
  }
  if (!schema) {
    return { keys: null, schema: null };
  }
  const keys = Object.keys(schema);
  if (!keys.length) {
    return { keys: null, schema: null };
  }

  return { keys, schema };
}

function getOptionsField({ device_schemas, datasourceId }) {
  if (!datasourceId) {
    return null;
  }

  const keysAndSchema = getDatasourceKeys({ device_schemas, datasourceId });
  const keys = keysAndSchema.keys;
  const schema = keysAndSchema.schema;

  let options = [];

  if (keys && schema) {
    // reformat output
    options = keys.map((key) => ({
      value: key,
      type: schema[key].type,
      label: schema[key].description ? schema[key].description : 'no description',
    }));
  }

  options.push({
    value: '@timestamp',
    label: 'Time',
  });

  return options;
}

const Option = (props) => {
  const secondline = props.data.type
    ? `${props.data.value} (${props.data.type})`
    : props.data.value;
  return (
    <components.Option {...props}>
      <div className="mw-100">
        <div className="truncate" title={props.data.label}>
          {props.data.label}
        </div>
        <div className="truncate" title={secondline}>
          <small>{secondline}</small>
        </div>
      </div>
    </components.Option>
  );
};

class DashboardFilterComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFilterOpen: false,
      filters: props.filter,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.submit = this.submit.bind(this);
    this.clearFilterRow = this.clearFilterRow.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.addEmptyFilter = this.addEmptyFilter.bind(this);
    this.updateFilterRowValue = this.updateFilterRowValue.bind(this);
  }

  componentDidMount() {
    if (this.state.filters == null) {
      this.setFilter([{ datasource: null }]);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.filter, this.props.filter)) {
      this.setFilter(this.props.filter);
    }
  }

  setFilter(filter) {
    this.setState({
      filters: filter,
    });
  }

  addEmptyFilter() {
    const newFilter = [...this.state.filters, { datasource: null }];
    this.setFilter(newFilter);
  }

  clearFilterRow(idx) {
    const newFilter = [...this.state.filters.slice(0, idx), ...this.state.filters.slice(idx + 1)];
    this.setState({
      filters: newFilter,
    });
  }

  updateFilterRowValue({ ds, fld, fldval, idx }) {
    const newObject = this.state.filters.slice(idx, idx + 1);

    if (ds !== undefined) {
      if (ds === null || (newObject[0].datasource && ds.value !== newObject[0].datasource.value)) {
        // reset all fields if datasource is nulled or different than before
        newObject[0].field = null;
        newObject[0].field_value = null;
      }
      newObject[0].datasource = ds;
    }

    if (fld !== undefined) {
      if (fld === null) {
        newObject[0].field_value = null;
      }
      newObject[0].field = fld;
    }

    if (fldval != null) {
      newObject[0].field_value = fldval;
    }

    const newFilter = [
      ...this.state.filters.slice(0, idx),
      ...newObject,
      ...this.state.filters.slice(idx + 1),
    ];

    this.setFilter(newFilter);
  }

  open() {
    this.setState(() => ({ isFilterOpen: true }));
  }

  close() {
    this.setState(() => ({ isFilterOpen: false }));
  }

  submit() {
    const { user, dashboard } = this.props;

    let user_id;
    if (user && dashboard && user.id !== dashboard.user_id) {
      // overwrite user_id because admin is editing it
      user_id = dashboard.user_id;
    }

    return patchDashboard(
      dashboard.id,
      {
        config: { ...dashboard.config, filters: this.state.filters },
      },
      user_id,
      dashboard.shared_dashboard && canEditDashboard(dashboard, user)
    ).then(
      (updatedDashboard) => {
        this.props.dashboardUpdated({ data: updatedDashboard });
        // history.push(
        //   `${location.pathname}?${queryString.stringify({ from: dashboard.date_from, to: dashboard.date_to })}`
        // );
        return null;
      },
      (error) => {
        throw new SubmissionError(error);
      }
    );
  }

  render() {
    const {
      intl: { formatMessage },
      datasourceOptions,
      device_schemas,
    } = this.props;

    return (
      <FilterContainer>
        <Button
          // label-ns={formatMessage(messages.FILTER)}
          title={formatMessage(messages.FILTER)}
          className="ml2"
          icon="fa fa-filter"
          onClick={this.state.isFilterOpen ? this.close : this.open}
        />
        {this.state.isFilterOpen ? (
          <FilterMenu
            onSubmit={(event) => {
              event.preventDefault();
              this.submit();
            }}
          >
            <CloseButton onClick={this.state.isFilterOpen ? this.close : this.open} />
            <FilterTitle>{formatMessage(messages.TITLE)}</FilterTitle>
            {this.state.filters.map((obj, idx) => (
              <FilterSection key={idx}>
                <HolderDiv>
                  <StyledSelect
                    key={`datasource-${idx}`}
                    value={getValue(obj.datasource && obj.datasource.value, datasourceOptions)}
                    // value={obj.datasource && obj.datasource.value}
                    options={datasourceOptions}
                    onChange={(datasource) =>
                      this.updateFilterRowValue({
                        ds: datasource,
                        idx,
                      })
                    }
                    onBlur={null}
                    placeholder={formatMessage(messages.PLACEHOLDER_DATASOURCE)}
                    noOptionsMessage={() => formatMessage(messages.NO_ITEM)}
                    isClearable={true}
                  />
                </HolderDiv>
                <HolderDiv>
                  {obj.datasource && obj.datasource.value ? (
                    <StyledSelect
                      key={`field-${idx}`}
                      // value={obj.field && obj.field.value}
                      value={getValue(
                        obj.field && obj.field.value,
                        getOptionsField({ device_schemas, datasourceId: obj.datasource.value })
                      )}
                      options={getOptionsField({
                        device_schemas,
                        datasourceId: obj.datasource.value,
                      })}
                      components={{ Option }}
                      onChange={(field) =>
                        this.updateFilterRowValue({
                          fld: field,
                          idx,
                        })
                      }
                      onBlur={null}
                      placeholder={formatMessage(messages.PLACEHOLDER_PROPERTY)}
                      isClearable={true}
                      noOptionsMessage={() => formatMessage(messages.NO_ITEM)}
                    />
                  ) : null}
                </HolderDiv>
                <HolderDiv>
                  {obj.field && obj.field.value ? (
                    <InputField
                      key={`value-${idx}`}
                      placeholder={formatMessage(messages.PLACEHOLDER_VALUE)}
                      type={obj.field.type ? obj.field.type : 'text'}
                      autoComplete="off"
                      onChange={(e) => {
                        this.updateFilterRowValue({ fldval: e.target.value, idx });
                      }}
                      value={obj.field_value || ''}
                    />
                  ) : null}
                </HolderDiv>
                {this.state.filters.length > 1 ? (
                  <ClearButton
                    type="button"
                    onClick={() => {
                      this.clearFilterRow(idx);
                    }}
                  >
                    <DeleteFilterButton />
                  </ClearButton>
                ) : null}
              </FilterSection>
            ))}
            <FilterButtonsHolder className="mt3">
              <Button
                onClick={() => this.addEmptyFilter()}
                type="button"
                label={formatMessage(messages.ADD_NEW)}
                icon="fa fa-plus"
              />
              <Button
                label-ns={formatMessage(messages.DELETE)}
                className="ml2"
                icon="fa fa-times"
                type="button"
                onClick={() => {
                  const emptyFilter = [{ datasource: null }];
                  this.setState(() => ({ filters: emptyFilter }));
                }}
              />
              <Button
                label-ns={formatMessage(messages.SAVE)}
                className="ml2"
                icon="fa fa-save"
                type="submit"
                activestyle="primary"
              />
            </FilterButtonsHolder>
          </FilterMenu>
        ) : null}
      </FilterContainer>
    );
  }
}

const memoizeDevices = memoize((state) => getDevicesReduced(state.machine));
const memoizeDatasourceOptions = memoize(
  (state) => state.machine && getOptionsDatasource(Object.values(state.machine))
);

export default connect(
  (state) => ({
    filter: state.search.filter,
    device_schemas: memoizeDevices(state),
    datasourceOptions: memoizeDatasourceOptions(state),
    user: state.auth.user,
  }),
  { dashboardUpdated }
)(injectIntl(DashboardFilterComponent));
