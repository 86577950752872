import { forwardRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Dashboard, Share } from '@mui/icons-material';
import classnames from 'classnames';

const HoldingDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0 0.5rem 1.2rem;
  text-decoration: none;
  &:hover {
    background: ${({ theme }) => theme.secondaryDiv.backgroundHover};
  }
`;

const DashboardIcon = styled(Dashboard)`
  margin-right: 0.1rem;
  color: ${({ theme }) => theme.secondaryDiv.color};
  cursor: move;
  outline: none;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;

const ShareIcon = styled(Share)`
  margin-right: 0.1rem;
  color: ${({ theme }) => theme.secondaryDiv.color};
  cursor: move;
  outline: none;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;

const LinkConn = styled(Link)`
  width: 100%;
  padding: 0.5rem 0 0.5rem 1.2rem;
  text-decoration: none;
  color: ${({ theme }) => theme.secondaryDiv.color};
`;

export const DashboardItemNoDrag = forwardRef(
  ({ id, opt, current_dashboard_id, sharing_active_label, ...props }, ref) => (
    <HoldingDiv {...props} ref={ref}>
      {opt.shared_user_name ? <ShareIcon /> : <DashboardIcon />}
      <LinkConn
        to={`${opt.path}`}
        state={{ currentDashboard: opt.object }}
        title={opt.description ? opt.description : null}
      >
        <span className={classnames({ green: opt.id === current_dashboard_id })}>
          {opt.label}
          {opt.external_user_name ? (
            <>
              <br />
              <small className="light-red">{opt.external_user_name}</small>
            </>
          ) : null}
          {opt.shared_user_name ? (
            <>
              <br />
              <small className="blue">{opt.shared_user_name}</small>
            </>
          ) : null}
          {opt.shared_users_count ? (
            <>
              <br />
              <small className="green">
                {sharing_active_label + ' [' + opt.shared_users_count + ']'}
              </small>
            </>
          ) : null}
        </span>
      </LinkConn>
    </HoldingDiv>
  )
);
