import { createAction } from 'redux-actions';

import {
  DASHBOARD_CREATED,
  DASHBOARD_UPDATED,
  DASHBOARD_DELETED,
  DASHBOARD_ORDER,
  WIDGET_CREATED,
  WIDGET_UPDATED,
  WIDGET_DELETED,
  WIDGETS_UPDATED,
  DASHBOARD_SHARING_UPDATED,
} from './constants';

export const widgetCreated = createAction(WIDGET_CREATED);
export const widgetUpdated = createAction(WIDGET_UPDATED);
export const widgetDeleted = createAction(WIDGET_DELETED);
export const widgetsUpdated = createAction(WIDGETS_UPDATED);

export const dashboardCreated = createAction(DASHBOARD_CREATED);
export const dashboardUpdated = createAction(DASHBOARD_UPDATED);
export const dashboardDeleted = createAction(DASHBOARD_DELETED);
export const dashboardOrderUpdated = createAction(DASHBOARD_ORDER);
export const dashboardSharingUpdated = createAction(DASHBOARD_SHARING_UPDATED);
