export function canEditDashboard(dashboard, user) {
  if (!dashboard) {
    return false;
  }
  if (!user) {
    return false;
  }

  if (!dashboard.shared_dashboard) {
    return true;
  }

  if (dashboard.user_id === user.id) {
    return true;
  }

  if (dashboard.shared_user_edit) {
    return true;
  }

  return false;
}

export function canCopySharedDashboard(dashboard, user) {
  if (!dashboard) {
    return false;
  }
  if (!user) {
    return false;
  }

  if (!dashboard.shared_dashboard) {
    return false;
  }

  if (dashboard.user_id === user.id) {
    return false;
  }

  if (dashboard.shared_user_copy) {
    return true;
  }

  return false;
}
