import request from './request';

export function listDashboard() {
  return request.get({ url: '/dashboard/' });
}
export function createDashboard(data, user_id) {
  if (user_id) {
    return request.post({ url: '/dashboard/', data, headers: { 'X-User-ID': user_id } });
  }
  return request.post({ url: '/dashboard/', data });
}
export function getDashboard(id) {
  return request.get({ url: `/dashboard/${id}/` });
}
export function getDashboardSharedUsers(id) {
  return request.get({ url: `/dashboard/${id}/shared_users` });
}
export function updateDashboardSharedUsers(id, data) {
  return request.post({ url: `/dashboard/${id}/shared_users`, data });
}
export function patchDashboard(id, data, user_id, shared_dashboard = false) {
  if (user_id && !shared_dashboard) {
    return request.patch({ url: `/dashboard/${id}/`, data, headers: { 'X-User-ID': user_id } });
  }
  if (shared_dashboard) {
    return request.patch({
      url: `/dashboard/${id}/`,
      data,
      headers: { 'X-Shared-Dashboard': true, 'X-User-ID': user_id },
    });
  }
  return request.patch({ url: `/dashboard/${id}/`, data });
}
export function copyDashboard(id, user_id, shared_dashboard = false) {
  if (user_id && !shared_dashboard) {
    return request.post({
      url: `/dashboard/${id}/copy`,
      headers: { 'X-User-ID': user_id },
      returnResponse: true,
    });
  }
  if (shared_dashboard) {
    return request.post({
      url: `/dashboard/${id}/copy`,
      headers: { 'X-Shared-Dashboard': true, 'X-User-ID': user_id },
      returnResponse: true,
    });
  }
  return request.post({ url: `/dashboard/${id}/copy`, returnResponse: true });
}
export function deleteDashboard(id) {
  return request.del({ url: `/dashboard/${id}/` });
}
export function createWidget(data, user_id, is_copy = false, shared_dashboard = false) {
  if (user_id && !shared_dashboard) {
    return request.post({
      url: '/widget/',
      data,
      headers: { 'X-User-ID': user_id, 'X-Copy': is_copy },
    });
  }
  if (is_copy === true && !shared_dashboard) {
    return request.post({ url: '/widget/', data, headers: { 'X-Copy': is_copy } });
  }
  if (shared_dashboard) {
    return request.post({
      url: '/widget/',
      data,
      headers: { 'X-Shared-Dashboard': true, 'X-Copy': is_copy, 'X-User-ID': user_id },
    });
  }
  return request.post({ url: '/widget/', data });
}
export function updateWidget(id, data, user_id, shared_dashboard = false) {
  if (user_id && !shared_dashboard) {
    return request.put({ url: `/widget/${id}/`, data, headers: { 'X-User-ID': user_id } });
  }
  if (shared_dashboard) {
    return request.put({
      url: `/widget/${id}/`,
      data,
      headers: { 'X-Shared-Dashboard': true, 'X-User-ID': user_id },
    });
  }
  return request.put({ url: `/widget/${id}/`, data });
}
export function deleteWidget(id, user_id, shared_dashboard = false) {
  if (user_id && !shared_dashboard) {
    return request.del({ url: `/widget/${id}/`, headers: { 'X-User-ID': user_id } });
  }
  if (shared_dashboard) {
    return request.del({
      url: `/widget/${id}/`,
      headers: { 'X-Shared-Dashboard': true, 'X-User-ID': user_id },
    });
  }
  return request.del({ url: `/widget/${id}/` });
}
